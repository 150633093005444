<template>
    <modal-lateral ref="modalFiltro" titulo="Filtro">
        <div class="custom-scroll overflow-auto pb-2" style="height:calc(100vh - 101px);">
            <div class="row mx-0 align-items-end">
                <div class="col">
                    <p class="label-mini">Valor</p>
                    <money v-model="filtro.valor_desde" class="input-money w-100" size="small" v-bind="money" />
                </div>
                <div class="col">
                    <money v-model="filtro.valor_hasta" class="input-money w-100" size="small" v-bind="money" />
                </div>
            </div>
            <div class="row mx-0 align-items-end mt-3">
                <div class="col">
                    <p class="label-mini">No. de productos</p>
                    <el-input v-model="filtro.productos_desde" placeholder="Desde" class="w-100" size="small" @change="soloNumeros('productos_desde')" />
                </div>
                <div class="col">
                    <el-input v-model="filtro.productos_hasta" placeholder="Hasta" class="w-100" size="small" @change="soloNumeros('productos_hasta')" />
                </div>
            </div>
            <div class="row mx-0 mt-3">
                <div class="col-12">
                    <p class="label-mini">Vendedores</p>
                    <el-select v-model="filtro.leechero" placeholder="Seleccione" class="w-100" size="small" clearable>
                        <el-option
                        v-for="item in leecheros"
                        :key="item.id"
                        :label="item.propietario_nombre"
                        :value="item.id"
                        />
                    </el-select>
                </div>
            </div>
            <template v-if="bandera === 'asignados'">
                <div class="row mx-0 mt-3">
                    <div class="col-12">
                        <p class="label-mini">Conductor</p>
                        <el-select v-model="filtro.conductor" placeholder="Seleccione" class="w-100" size="small" clearable>
                            <el-option
                            v-for="item in conductores"
                            :key="item.id"
                            :label="item.conductor"
                            :value="item.conductor"
                            />
                        </el-select>
                    </div>
                </div>
                <div class="row mx-0 mt-3">
                    <div class="col-12">
                        <p class="label-mini">Vehículo</p>
                        <el-select v-model="filtro.vehiculo" placeholder="Seleccione" class="w-100" size="small" clearable>
                            <el-option
                            v-for="item in infoVehiculo"
                            :key="item.id"
                            :label="item.vehiculo"
                            :value="item.vehiculo"
                            />
                        </el-select>
                    </div>
                </div>
                <div class="row mx-0 mt-3">
                    <div class="col-12">
                        <p class="label-mini">Estado operador logístico</p>
                        <el-select v-model="filtro.estado_logistico" placeholder="Seleccione" class="w-100" size="small" clearable>
                            <el-option
                            v-for="item in estadoLogisticos"
                            :key="item.id"
                            :label="item.estado_logistico"
                            :value="item.id"
                            />
                        </el-select>
                    </div>
                </div>
            </template>
            <div class="row mx-0 align-items-end mt-3">
                <div class="col">
                    <p class="label-mini">Peso</p>
                    <el-input v-model="filtro.peso_desde" placeholder="Desde" class="w-100" size="small" @change="soloNumeros('peso_desde')" />
                </div>
                <div class="col">
                    <el-input v-model="filtro.peso_hasta" placeholder="Hasta" class="w-100" size="small" @change="soloNumeros('peso_hasta')" />
                </div>
            </div>
            <div class="row mx-0 align-items-end mt-3">
                <div class="col">
                    <p class="label-mini">Volumen</p>
                    <el-input v-model="filtro.volumen_desde" placeholder="Desde" class="w-100" size="small" @change="soloNumeros('volumen_desde')" />
                </div>
                <div class="col">
                    <el-input v-model="filtro.volumen_hasta" placeholder="Hasta" class="w-100" size="small" @change="soloNumeros('volumen_hasta')" />
                </div>
            </div>
        </div>
        <div v-if="$route.name == 'admin.rutas-entrega-2.historial-pedidos'" class="row mx-0 justify-center">
            <div class="btn-general px-5 f-14">
                Buscar pedidos
            </div>
        </div>
        <div v-else class="row mx-0 justify-content-center">
            <div class="btn-outline px-5 f-14 mx-2" @click="limpiar()">
                Limpiar
            </div>
            <div class="btn-general px-5 f-14 mx-2" @click="filtrar()">
                Filtrar
            </div>
        </div>
    </modal-lateral>
</template>

<script>
const initialState = {
    valor_desde: '',
    valor_hasta: '',
    productos_desde: '',
    productos_hasta: '',
    leechero: '',
    conductor: '',
    vehiculo: '',
    estado_leeche: '',
    estado_logistico: '',
    peso_desde: '',
    peso_hasta: '',
    volumen_desde: '',
    volumen_hasta: '',
}
import tablero from '~/services/routing/tablero'
import {Money} from 'v-money'
export default {
    components:{
        Money,
    },
    data(){
        return {
            filtro: { ...initialState },
            leecheros: [],
            infoVehiculo: [],
            estadoLogisticos: [],
            bandera: this.bandera,
            money: {
                thousands: '.',
                prefix: '$ ',
                precision: 0,
                masked: false
            },
        }
    },

    computed: {
        conductores(){
            if(_.isEmpty(this.infoVehiculo)) return []
            return _.chain(this.infoVehiculo)
                .uniqBy('nombre')
                .value()
        }
    },

    mounted(){
        this.$emit('filtrar', this.filtro);
    },

    methods: {
        async toggle(bandera){
            this.bandera = bandera;
            await this.consultarFiltro(bandera)
            this.$refs.modalFiltro.toggle();
        },
        filtrar(){
            this.$emit('filtrar', this.filtro);
            this.$refs.modalFiltro.toggle();
        },
        limpiar(){
            this.filtro = { ...initialState }
            this.filtrar()
        },
        async consultarFiltro(bandera){
            try {
                let params = {
                    bandera: bandera
                }
                const { data } = await tablero.consultarFiltro(params);

                this.infoVehiculo = data.info_vehiculo
                this.leecheros = data.leecheros
                this.estadoLogisticos = data.estado_logistico
            } catch (error){
                this.error_catch(error)
            }
        },
        async soloNumeros(bandera){
            const soloNumeros = /^[0-9]+$/;
            if (bandera === "productos_desde"){
                if (!this.filtro.productos_desde.match(soloNumeros)){
                    this.filtro.productos_desde = '';
                }
            }
            if (bandera === "productos_hasta"){
                if (!this.filtro.productos_hasta.match(soloNumeros)){
                    this.filtro.productos_hasta = '';
                }
            }
            if (bandera === "volumen_desde"){
                if (!this.filtro.volumen_desde.match(soloNumeros)){
                    this.filtro.volumen_desde = '';
                }
            }
            if (bandera === "volumen_hasta"){
                if (!this.filtro.volumen_hasta.match(soloNumeros)){
                    this.filtro.volumen_hasta = '';
                }
            }
            if (bandera === "peso_desde"){
                if (!this.filtro.peso_desde.match(soloNumeros)){
                    this.filtro.peso_desde = '';
                }
            }
            if (bandera === "peso_hasta"){
                if (!this.filtro.peso_hasta.match(soloNumeros)){
                    this.filtro.peso_hasta = '';
                }
            }
        },
    }
}
</script>
<style lang="scss" scoped>
.label-mini{
    font-size: 12px;
    padding-left: 15px;
    color: #000000;
}
</style>
